import * as React from 'react'
import Pagewrapper from '../../../../components/PageWrapper'
import BankRedirect from '../../../../components/Bank/BankRedirect'

function BankAuthRedirectPage() {
    return (
        <Pagewrapper>
            <BankRedirect />
        </Pagewrapper>
    )
}

export default BankAuthRedirectPage
