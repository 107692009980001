import { Account, BankInstitution } from '../types/banks'
import { getStoredAuthToken } from '../utils/auth-token'
import { API_URL, httpDelete, httpGet, httpPost, httpPut } from '../services/http'

export async function getBankInstitutions(countryCode: string): Promise<BankInstitution[]> {
    const response = await httpGet<{ institutions: BankInstitution[] }>(
        `/banks/institutions/?country_code=${countryCode}`
    )
    return response?.data?.institutions || []
}

export async function getAuthorisationLink(bankId: string): Promise<string | undefined> {
    const response = await httpPost<{ authorisation_link: string }>(`/banks/${bankId}/`)
    return response?.data?.authorisation_link
}

export async function getNordigenRequisition(requisitionId: string, idAsReference = false) {
    const response = await httpGet<any>(
        `/banks/nordigen/requisitions/${requisitionId}/?is_reference=${idAsReference}`
    )
    return response?.data
}

export async function getAccounts() {
    const response = await httpGet<{ accounts: Account[] }>(`/banks/accounts/`)
    return response?.data?.accounts || []
}

export async function getAccount(accountId: string) {
    const response = await httpGet<{ account: Account }>(`/banks/accounts/${accountId}/`)
    return response?.data?.account || null
}

export async function updateAccountInclusion(accountId: string, inclusion_percentage: number) {
    await httpPut(`/organisations/${getCurrentOrganisation()}/banks/accounts/${accountId}`, {
        inclusion_percentage,
    })
}

export async function refreshAccounts() {
    await httpPost<void>(`/banks/accounts/refresh/`)
}

export async function getAccountTransactions(accountId: string) {
    const response = await httpGet<{ transactions: any[] }>(
        `/banks/accounts/${accountId}/transactions/`
    )
    return response?.data?.transactions || []
}

export async function getAccountTransaction(accountId: string, transactionId: string) {
    const response = await httpGet<{ transaction: any }>(
        `/banks/accounts/${accountId}/transactions/${transactionId}/`
    )
    return response?.data?.transaction
}

export async function buildDocumentForTransaction(
    accountId: string,
    transactionId: string,
    documentDate: string
) {
    await httpPost(`/banks/accounts/${accountId}/transactions/${transactionId}/documents/`, {
        document_date: documentDate,
    })
}

export async function getAllTransactions() {
    const response = await httpGet<{ transactions: any[] }>(
        `/organisations/${getCurrentOrganisation()}/banks/transactions/`
    )
    return response?.data?.transactions || []
}

export async function getCategories() {
    const response = await httpGet<{ categories: any[] }>(
        `/organisations/${getCurrentOrganisation()}/banks/transactions/categories`
    )
    return response?.data?.categories || []
}

export async function updateCategory(categoryId: string, updateBody: any) {
    await httpPut(
        `/organisations/${getCurrentOrganisation()}/banks/transactions/categories/${categoryId}`,
        updateBody
    )
}

export async function updateTransaction(transactionId: string, updateBody: any) {
    const response = await httpPut<{ transaction: any }>(
        `/organisations/${getCurrentOrganisation()}/banks/transactions/${transactionId}`,
        updateBody
    )
    return response?.data?.transaction
}

export async function uploadFileToTransaction(transactionId: string, updateBody: FormData) {
    await httpPost(
        `/organisations/${getCurrentOrganisation()}/banks/transactions/${transactionId}/files`,
        updateBody,
        true
    )
}

export async function getDocumentsForTransaction(transactionId: string) {
    const response = await httpGet<{ document_ids: any[] }>(
        `/organisations/${getCurrentOrganisation()}/banks/transactions/${transactionId}/documents/`
    )
    return response?.data?.document_ids || []
}

export function getDocumentSourceLink(documentId: string) {
    return `${API_URL}/organisations/${getCurrentOrganisation()}/files/${documentId}/auth/${getStoredAuthToken()}`
}

export async function deleteDocumentsOnTransaction(transactionId: string, documentId: string) {
    await httpDelete(
        `/organisations/${getCurrentOrganisation()}/banks/transactions/${transactionId}/documents/${documentId}`
    )
}

export function getDocumentExtractionsSourceLink(documentId: string) {
    return `${API_URL}/organisations/${getCurrentOrganisation()}/files/${documentId}/extractions/auth/${getStoredAuthToken()}`
}

export async function rerunExtractionFordocument(documentId: string) {
    await httpPost(`/organisations/${getCurrentOrganisation()}/documents/${documentId}/extractions`)
}
