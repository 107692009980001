import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert'

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export default function createAlertSnackbar(defaultSeverity: AlertColor) {
    const [open, setOpen] = React.useState(false)
    const [message, setMessage] = React.useState('')
    const [severity, setSeverity] = React.useState(defaultSeverity)

    const handleOpen = (message: string, severity?: AlertColor) => {
        if (severity) {
            setSeverity(severity)
        }
        setMessage(message)
        setOpen(true)
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    return {
        openAlert: handleOpen,
        AlertComponent: (
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        ),
    }
}
