import * as React from 'react'
import { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { useLocation } from '@reach/router'
import { BanksPaper } from './paperComponents'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import { ConfirmButton } from '../input/styleOverrides'
import Typography from '@mui/material/Typography'
import createAlertSnackbar from '../dialogs/snackbars'
import { getNordigenRequisition } from '../../api/banks'

export default function BankRedirectHandler() {
    let location = useLocation()
    const [status, setStatus] = useState<boolean | null>(null)
    const { openAlert, AlertComponent } = createAlertSnackbar('error')
    // useAuth('')

    useEffect(() => {
        const requisitionId = new URLSearchParams(location.search).get('ref')
        if (!requisitionId) return
        getNordigenRequisition(requisitionId, true)
            .then((data: { requisition: { integration_status: string } }) => {
                if (data.requisition.integration_status == 'LN') {
                    setStatus(true)
                }
            })
            .catch((error) => {
                openAlert(`Something went wrong: ${error.toString()}`)
                setStatus(false)
            })
    }, [location.search])

    return (
        <BanksPaper>
            {AlertComponent}
            <div>
                {(status === null && (
                    <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                    </Box>
                )) ||
                    (status === true && (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" component="text" sx={{ flexGrow: 1 }}>
                                Successfully integrated to the bank!
                            </Typography>
                            <ConfirmButton
                                onClick={() => navigate('/app')}
                                type="submit"
                                size="large"
                                variant="contained"
                            >
                                Go back to App
                            </ConfirmButton>
                        </Box>
                    )) ||
                    (status === false && (
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography variant="h6" component="text" sx={{ flexGrow: 1 }}>
                                The service may take a couple of minutes before the process comes
                                through. You can go back to the app and await the processing. If you
                                think something is wrong feel free to contact us!
                            </Typography>
                            <ConfirmButton
                                onClick={() => navigate('/app')}
                                type="submit"
                                size="large"
                                variant="contained"
                            >
                                Go back to App
                            </ConfirmButton>
                        </Box>
                    ))}
            </div>
        </BanksPaper>
    )
}
